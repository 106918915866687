<template>
    <div class="mini-item mini-enhancement-slot" :data-id="item.id">
        <v-tooltip right max-width="400" content-class="mini-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <div class="item-box"
                     v-bind="attrs"
                     v-on="on"
                >
                    <div class="item-content">
                        <img v-if="item.img" :src="item.img" class="thumbnail-img"/>
                        <img v-if="item.image" :src="item.image" class="thumbnail-img"/>
                    </div>
                </div>
            </template>
            <span class="tooltip-content" v-if="item.name">
                <span class="tooltip-title">{{ item.name }}</span>
                {{ item.description }}
            </span>
        </v-tooltip>
    </div>
</template>

<script>

export default {
    props: {
        item: Object,
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.mini-item.mini-enhancement-slot {
    margin: auto;
    position: relative;

    .item-box {
        width: 120px;
        height: 120px;
        background: url('/img/summoning/png/niche@72x.png') 0 0 no-repeat;
        background-size: cover;

        &:hover {
            background: url('/img/summoning/png/niche@72x.png') 0 0 no-repeat;
            background-size: cover;
        }

        img.thumbnail-img {
            width: 60px;
            object-fit: contain;
            margin: 0;
        }
    }
}
</style>
