export default new class SummonCostService {
    /**
     * @param {Array} costPerWizardSouls
     * @param {Array} remainingSoulsPerWizardArray
     * @param {Number} enhancementAmount
     * @return {null|number}
     */
    summonCost(costPerWizardSouls, remainingSoulsPerWizardArray, enhancementAmount) {
        if (2 === remainingSoulsPerWizardArray.length) {
            return this.#getCostFromArray(costPerWizardSouls, remainingSoulsPerWizardArray[0]) + this.#getCostFromArray(costPerWizardSouls, remainingSoulsPerWizardArray[1]);
        }

        if (1 === remainingSoulsPerWizardArray.length && 1 === enhancementAmount) {
            return this.#getCostFromArray(costPerWizardSouls, remainingSoulsPerWizardArray[0]) * 2;
        }

        if (0 === remainingSoulsPerWizardArray.length && 2 === enhancementAmount) {
            return costPerWizardSouls.at(-1) * 2;
        }

        return null;
    }

    #getCostFromArray(costPerWizardSoulsArray, remainingSouls) {
        if (costPerWizardSoulsArray.length < remainingSouls) {
            return costPerWizardSoulsArray.at(-1);
        }

        return costPerWizardSoulsArray[remainingSouls - 1];
    }
}
