<template>
    <v-container class="ritual fill-height">
        <v-row no-gutters class="fill-height">
            <v-col v-for="(wizard, i) in wizards" :key="`wizard_${i}`"
                   :order="i===0 ? 'first' : 'last'"
                   class="py-0"
                   v-on:mouseenter="wizard.hover=true" v-on:mouseleave="wizard.hover=false"
                   v-on:click="selectParticipant(participants.wizards[i].type || 'wizard', participants.wizards[i].data, i)"
            >
                <summoning-ritual-participant
                    :selected="participants.wizards[i].selected" :hover="wizard.hover"
                    placeholder-name="wizard" :participant="participants.wizards[i].data"
                    :mirror="i!==0"
                />
            </v-col>
            <v-col key="portal" class="py-0" cols="5"
                   v-on:mouseenter="land.hover=true" v-on:mouseleave="land.hover=false"
                   v-on:click="selectParticipant(participants.land.type || 'land', participants.land.data)">
                <summoning-ritual-participant
                    :selected="participants.land.selected"
                    :hover="land.hover"
                    placeholder-name="portal" :participant="participants.land.data"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SummoningRitualParticipant from "@/components/summoning/summoning-ritual-participant";

export default {
    components: {SummoningRitualParticipant},
    props: {
        participants: Object
    },
    data() {
        return {
            wizards: [
                {hover: false},
                {hover: false}
            ],
            land: {hover: false,}
        };
    },
    methods: {
        selectParticipant(type, data, no = 0) {
            this.$emit('select-participant', {type: type, data: data, no: no})
        },
    }
}
</script>

<style lang="scss" scoped>
.ritual {
    background-size: 100% 100%;
    padding: 4% 1.5% 4% 3%;
    background-image: url('/img/summoning/png/ritual-bg@72x.png');
}
</style>
