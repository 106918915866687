<template>
    <div class="ui-container d-flex flex-column align-stretch mb-4">
        <div class="dashboard-container d-flex flex-column flex-sm-row align-stretch flex-grow-1 flex-shrink-0">
            <div class="dashboard-spacer"></div>
            <!--            //TODO: DEBUG-->
            <!--            <pre style="position:absolute;font-size:50%;width: 800px;height:100%;">{{ participants }}</pre>-->
            <!--            //TODO: END-DEBUG-->
            <div
                class="container-off-selected d-flex flex-column flex-md-row justify-space-between align-sm-stretch flex-grow-0 flex-md-grow-1">
                <div class="ritual-container mx-auto px-a py-1 flex-grow-1 flex-md-grow-0">
                    <summoning-ritual
                        :participants="participants"
                        @select-participant="handleSelectParticipant"
                    ></summoning-ritual>
                </div>
                <div class="ingredients-container flex-grow-0 flex-md-grow-1">
                    <summoning-ingredients
                        :ingredients="participants.ingredients"
                        @select-participant="handleSelectParticipant"
                    >
                    </summoning-ingredients>
                </div>
            </div>
            <div class="details-container mx-auto px-2">
                <summoning-details-overview
                    :entity="selectedEntity"
                    :button-class="universalButtonClass"
                    @universal-button-click="handleUniversalButtonClick()"
                ></summoning-details-overview>
            </div>
            <div class="dashboard-spacer"></div>
        </div>
        <div class="summon-container">
            <summoning-summon-controls :participants="participants"
                                       :participant-land-id="participantLandId"
                                       :participant-enchantment-land-id="participantEnchantmentLandId"
                                       :participant-wizard-ids="participantWizardIds"
                                       :participant-enchantment-wizard-ids="participantEnchantmentWizardIds"
                                       :participant-ingredient-ids="participantIngredientIds">

            </summoning-summon-controls>
        </div>
        <div class="entities-container">
            <summoning-entities-browser @select-entity="handleSelectEntity"
                                        :display-lands="selectedParticipantType === 'land'"
                                        :display-wizards="selectedParticipantType === 'wizard'"
                                        :display-ingredients="selectedParticipantType === 'ingredient'"
                                        :participant-land-id="participantLandId"
                                        :participant-enchantment-land-id="participantEnchantmentLandId"
                                        :participant-wizard-ids="participantWizardIds"
                                        :participant-enchantment-wizard-ids="participantEnchantmentWizardIds"
                                        :participant-ingredient-ids="participantIngredientIds">
            </summoning-entities-browser>
        </div>
    </div>
</template>

<script>
import SummoningEntitiesBrowser from "../components/summoning/summoning-entities-browser";
import SummoningSummonControls from "../components/summoning/summoning-summon-controls";
import SummoningDetailsOverview from "../components/summoning/summoning-details-overview";
import SummoningRitual from "../components/summoning/summoning-ritual";
import SummoningIngredients from "../components/summoning/summoning-ingredients";
import goTo from "vuetify/lib/services/goto";
import Vue from "vue";

export default {
    components: {
        SummoningEntitiesBrowser,
        SummoningSummonControls,
        SummoningDetailsOverview,
        SummoningRitual,
        SummoningIngredients
    },
    data() {
        return {
            selectedEntity: {
                type: 'wizard',
                data: {},
            },
            participants: {
                wizards: [
                    {
                        selected: true,
                        type: null,
                        data: null
                    },
                    {
                        selected: false,
                        type: null,
                        data: null
                    },
                ],
                land: {
                    selected: false,
                    type: null,
                    data: null
                },
                ingredients: [
                    {
                        selected: false,
                        type: null,
                        data: null,
                    },
                    {
                        selected: false,
                        type: null,
                        data: null,
                    },
                    {
                        selected: false,
                        type: null,
                        data: null,
                    }
                ]
            }
        };
    },
    computed: {
        participantsArray() {
            return [
                ...this.participants.wizards,
                this.participants.land,
                ...this.participants.ingredients
            ];
        },
        selectedParticipant() {
            return this.participantsArray
                .filter(participant => participant.selected)[0] || {};
        },
        selectedParticipantType() {
            function isSelectedLand(that) {
                return that.participants.land.selected;
            }

            function isSelectedWizard(that) {
                return that.participants.wizards.filter(item => item.selected).length > 0;
            }

            function isSelectedIngredient(that) {
                return that.participants.ingredients.filter(item => item.selected).length > 0;
            }

            switch (true) {
                case isSelectedLand(this):
                    return 'land';
                case isSelectedWizard(this):
                    return 'wizard';
                case isSelectedIngredient(this):
                    return 'ingredient';
                default:
                    return 'none';
            }
        },
        participantLandId() {
            return [this.participants.land]
                .filter(w => !!w?.data?.id)
                .filter(w => 'land' === w.type)
                .map(w => w.data.id)[0] || null;
        },
        participantEnchantmentLandId() {
            return [this.participants.land]
                .filter(w => !!w?.data?.id)
                .filter(w => 'ingredient-land' === w.type)
                .map(w => w.data.id)[0];
        },
        participantWizardIds() {
            return this.participants.wizards
                .filter(w => !!w?.data?.id)
                .filter(w => 'wizard' === w.type)
                .map(w => w.data.id);
        },
        participantEnchantmentWizardIds() {
            return this.participants.wizards
                .filter(w => !!w?.data?.id)
                .filter(w => 'ingredient-wizard' === w.type)
                .map(w => w.data.id);
        },
        participantIngredientIds() {
            return this.participants.ingredients
                .filter(w => !!w?.data?.id)
                .map(w => parseInt(w.data.id));
        },
        universalButtonClass() {
            switch (true) {
                case !this.selectedEntity?.data?.id || !this.selectedEntityIsSameTypeAsSelectedParticipant:
                    return 'search';
                case !!this.selectedParticipant?.data?.id && this.selectedEntity?.data?.id === this.selectedParticipant.data.id:
                    return 'remove';
                case !!this.selectedEntity?.data?.id && !this.selectedParticipant?.data?.id: //TODO: allows to choose 2x the same Wizard
                    return 'add';
                case this.selectedEntity?.data?.id !== this.selectedParticipant.data.id:
                    return 'replace';
            }
        },
        selectedEntityIsSameTypeAsSelectedParticipant() {
            return this.isLandType(this.selectedEntity?.type) && this.isLandType(this.selectedParticipantType)
                || this.isWizardType(this.selectedEntity?.type) && this.isWizardType(this.selectedParticipantType)
                || this.isIngredientType(this.selectedEntity?.type) && this.isIngredientType(this.selectedParticipantType)

        },
    },
    methods: {
        handleSelectEntity({type, data}) {
            //scrolls UI
            goTo('.container-off-selected')
            this.selectedEntity = {
                type: type,
                data: data
            }
            this.unselectParticipantIfOtherTypeThanSelectedEntity()

            if (!Boolean(this.selectedParticipant?.data?.id)) {
                this.addSelectedEntityAsParticipant();
            }
        },
        unselectParticipantIfOtherTypeThanSelectedEntity() {
            if (this.isLandType(this.selectedEntity.type)) {
                this.participants.wizards.forEach(w => w.selected = false);
                this.participants.ingredients.forEach(i => i.selected = false);
            } else if (this.isWizardType(this.selectedEntity.type)) {
                this.participants.land.selected = false;
                this.participants.ingredients.forEach(i => i.selected = false);
            } else if (this.selectedEntity.type === 'ingredient') {
                this.participants.wizards.forEach(w => w.selected = false);
                this.participants.land.selected = false;
            }
        },
        isWizardType(type) {
            return ['wizard', 'ingredient-wizard'].includes(type)
        },
        isLandType(type) {
            return ['land', 'ingredient-land'].includes(type)
        },
        isIngredientType(type) {
            return ['ingredient'].includes(type)
        },
        isEnhancementWizardLandType(type) {
            return ['ingredient-wizard', 'ingredient-land'].includes(type);
        },
        handleSelectParticipant({type, data, no}) {
            let selectedParticipant = {};
            if (this.isLandType(type)) {
                selectedParticipant = this.participants.land;
            } else if (this.isWizardType(type)) {
                selectedParticipant = this.participants.wizards[no];
            } else if (type === 'ingredient') {
                selectedParticipant = this.participants.ingredients[no];
            }
            const secondClick = selectedParticipant.selected;

            this.participantsArray.forEach(p => {
                p.selected = (p === selectedParticipant);
            });

            if (
                type !== this.selectedEntity.type
                || !this.selectedEntity?.data?.id
                || secondClick
            ) {
                this.selectedEntity = {
                    type: type,
                    data: data
                }
            }
        },
        handleUniversalButtonClick() {
            switch (this.universalButtonClass) {
                case 'search':
                    //scrolls UI
                    goTo('.entities-container')
                    break;
                case 'remove':
                    this.removeSelectedEphemeralFromIngredients();
                    this.removeSelectedEnhancementFromIngredients();
                    this.selectedParticipant.data = null;
                    break;
                case 'replace':
                    const swapMemory = {
                        type: this.selectedParticipant.type,
                        data: this.selectedParticipant.data
                    };
                    this.removeSelectedEphemeralFromIngredients();
                    this.removeSelectedEnhancementFromIngredients();
                    this.selectedParticipant.data = this.selectedEntity.data;
                    this.selectedParticipant.type = this.selectedEntity.type;
                    this.addEnhancementIngredients();
                    this.addEphemeralToIngredients();
                    this.selectedEntity = swapMemory;
                    break;
                case 'add':
                    this.addSelectedEntityAsParticipant();
                    break;
            }
        },
        addSelectedEntityAsParticipant() {
            this.selectedParticipant.data = this.selectedEntity.data;
            this.selectedParticipant.type = this.selectedEntity.type;
            this.addEphemeralToIngredients();
            this.addEnhancementIngredients();
            this.participantsArray.forEach(p => p.selected = false);
            this.selectNextFreeParticipant();
        },
        selectNextFreeParticipant() {
            const nextParticipant = this.participantsArray.filter(p => !Boolean(p?.data?.id))[0];
            if (Boolean(nextParticipant)) {
                nextParticipant.selected = true;
                this.selectedEntity = {
                    type: this.selectedParticipantType,
                    data: {}
                }
            } else {
                this.selectedEntity = {}
            }
        },
        addEphemeralToIngredients() {
            if (this.isEnhancementWizardLandType(this.selectedEntity.type)) {
                this.selectedEntity.data.amount--;

                // Put on Enhancement list
                for (let i = 0; i < this.participants.ingredients.length; i++) {
                    if (!this.isEnhancementWizardLandType((this.participants.ingredients[i].type))) {
                        // this.participants.ingredients[i] = this.selectedEntity;
                        Vue.set(this.participants.ingredients, i, this.selectedEntity);
                        break;
                    }
                }
            }
        },
        removeSelectedEphemeralFromIngredients() {
            if (this.isEnhancementWizardLandType(this.selectedParticipant.type)) {
                this.selectedParticipant.data.amount++;

                // Remove from Enhancement list
                for (let i = this.participants.ingredients.length - 1; i >= 0; i--) {
                    if (this.participants.ingredients[i]?.data
                        && this.participants.ingredients[i]?.data?.id
                        && this.participants.ingredients[i]?.data?.id === this.selectedParticipant.data.id
                    ) {
                        // this.participants.ingredients[i] = {typ  e: null};
                        Vue.set(this.participants.ingredients, i, {type: null, selected: false, data: null});
                        break;
                    }
                }
            }
        },
        addEnhancementIngredients() {
            if ('ingredient' === this.selectedEntity.type) {
                this.selectedEntity.data.amount--;
            }
        },
        removeSelectedEnhancementFromIngredients() {
            if ('ingredient' === this.selectedParticipant.type) {
                this.selectedParticipant.data.amount++;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.ui-container {
}

.dashboard-container {
    background-color: #5c7a86;
}

.dashboard-spacer {
    width: 6vw;
}

.dashboard-wing {
    width: 40vw;
}

.container-off-selected {
}

.ingredients-container {
}

.ritual-container {
    @extend .dashboard-wing;
}

.details-container {
    @extend .dashboard-wing;
}

.summon-container {
    width: 100%;
    background-color: #5c7a86;
    border-bottom: 5px solid #34495a;
}

@media only screen and (max-width: $sm) {
    .dashboard-wing {
        width: 49vw;
    }
    .dashboard-spacer {
        display: none;
    }
    .ingredients-container {
        height: 25%;
    }
}

@media only screen and (max-width: $xs) {
    .dashboard-wing {
        width: 90vw;
    }

    .details-container {
        height: 60vw;
    }

}
</style>
