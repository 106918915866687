var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { small: _vm.small } },
    [
      _c("img", { attrs: { src: "/img/scroll_48.png" } }),
      _vm.price === null && !_vm.displayPlaceholder
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _vm.displayPlaceholder ? _c("span", [_vm._v("  -  ")]) : _vm._e(),
      _vm.price !== null && !_vm.displayPlaceholder
        ? _c("span", [
            _vm._v(" " + _vm._s(_vm._f("localFormatNumber")(_vm.price)) + " "),
          ])
        : _vm._e(),
      _vm.tokenName ? _c("span", [_vm._v(" SCRL ")]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }