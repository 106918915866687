var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-100 pa-1 pb-3 d-flex flex-wrap align-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "w-100 d-flex flex-column flex-sm-row align-center justify-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "px-2 pt-1 mx-8 d-flex flex-column align-center justify-center bottom-btn",
            },
            [
              _vm.allowances.filter((a) => {
                return a.shouldDisplay()
              }).length > 0
                ? _c("span", { staticClass: "text-subtitle-2" }, [
                    _vm._v(" Required allowances: "),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "allowance-buttons d-flex flex-column flex-md-row",
                },
                _vm._l(_vm.allowances, function (allowance, i) {
                  return allowance.shouldDisplay()
                    ? _c(
                        "main-button",
                        {
                          key: `allowance_${i}: ${allowance.name}`,
                          class: {
                            disabled: allowance.allowed || !allowance.checked,
                          },
                          attrs: {
                            disabled: allowance.allowed || !allowance.checked,
                            loading: !allowance.checked || allowance.loading,
                            "on-click": () =>
                              _vm.handleApproveRequestClick(allowance),
                          },
                        },
                        [
                          allowance.allowed
                            ? _c("v-icon", { attrs: { size: "small" } }, [
                                _vm._v("mdi-check-outline"),
                              ])
                            : _c("v-icon", { attrs: { size: "small" } }, [
                                _vm._v("mdi-alert-circle-outline"),
                              ]),
                          _vm._v(" " + _vm._s(allowance.name) + " "),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column-reverse flex-md-row align-center",
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "div",
                            _vm._g(_vm._b({}, "div", attrs, false), on),
                            [
                              _c(
                                "main-button",
                                {
                                  attrs: {
                                    disabled: _vm.summoningDisabled,
                                    "on-click": _vm.openSummary,
                                    big: "",
                                    glow: "",
                                  },
                                },
                                [_vm._v(" Summon ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("div", [
                    !_vm.summoningDisabled
                      ? _c("span", [
                          _vm._v("Click to open confirmation window"),
                        ])
                      : _c(
                          "ul",
                          _vm._l(_vm.summoningWarnings, function (warning, i) {
                            return _c("li", { key: "warn-" + i }, [
                              _vm._v(_vm._s(warning)),
                            ])
                          }),
                          0
                        ),
                  ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "div",
                            _vm._g(_vm._b({}, "div", attrs, false), on),
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "summon-cost-box d-flex flex-row",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "bottom-btn" },
                                    [
                                      _c("price", {
                                        attrs: {
                                          price: _vm.summonCost,
                                          "display-placeholder":
                                            null === _vm.summonCost,
                                          "token-name": "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { margin: "-6px 0 0 -10px" },
                                    },
                                    [_vm._v("mdi-help-circle-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    [
                      _c("h4", { staticClass: "mt-2 mb-2" }, [
                        _vm._v("Summoning cost"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Depends on remaining souls amount of participating Wizards"
                        ),
                      ]),
                      _c("v-data-table", {
                        staticClass: "mb-3",
                        attrs: {
                          headers: [
                            {
                              text: "Remaining souls",
                              value: "souls",
                              width: 150,
                              align: "end",
                            },
                            { text: "Cost per Wizard", value: "cost" },
                          ],
                          items: [
                            { souls: 5, cost: _vm.summonCostsFromApi[4] },
                            { souls: 4, cost: _vm.summonCostsFromApi[3] },
                            { souls: 3, cost: _vm.summonCostsFromApi[2] },
                            { souls: 2, cost: _vm.summonCostsFromApi[1] },
                            { souls: 1, cost: _vm.summonCostsFromApi[0] },
                          ],
                          dense: "",
                          "hide-default-footer": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.cost",
                            fn: function ({ item }) {
                              return [
                                _c("price", {
                                  attrs: {
                                    price: item.cost,
                                    small: "",
                                    displayPlaceholder: !item.cost,
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            id: "SummonSummary",
            model: _vm.summaryDialogOpen,
            title: "Ritual Summary",
            "remove-v-card-wrap": "",
            width: "800px",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.summaryDialogOpen = false
            },
          },
        },
        [
          _c(
            "v-card-text",
            [
              _vm.participantsValid
                ? _c(
                    "v-container",
                    { staticClass: "fill-height ma-0 pa-0" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-5", attrs: { "no-gutters": "" } },
                        _vm._l(
                          [
                            _vm.participants.wizards[0],
                            _vm.participants.land,
                            _vm.participants.wizards[1],
                          ].map((p) => p.data),
                          function (participant, i) {
                            return _c(
                              "v-col",
                              {
                                key: "part" + i,
                                class: { "wizard-column": i !== 1 },
                                attrs: {
                                  order: i === 1 ? 4 : i,
                                  "order-sm": i,
                                },
                              },
                              [
                                _c("div", { staticClass: "borderline" }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex text-center flex-column align-center justify-space-between",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-1 pt-2 font-weight-bold golden--text summary-participant-name",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(participant.name) + " "
                                        ),
                                      ]
                                    ),
                                    _c("img", {
                                      staticClass: "dialog--participant-image",
                                      class: { mirrored: i === 2 },
                                      attrs: { src: _vm.getImage(participant) },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "text--primary" },
                                      [
                                        _vm.haveSoul(participant)
                                          ? _c("span", [
                                              _vm._v(
                                                " Souls: " +
                                                  _vm._s(
                                                    participant.wizarre
                                                      .souls_remaining
                                                  ) +
                                                  " / " +
                                                  _vm._s(
                                                    participant.wizarre
                                                      .souls_max
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        participant.hasOwnProperty("wizarre") &&
                                        participant.wizarre.typeName
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    participant.wizarre.typeName
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v(" ")]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "borderline my-4 d-flex align-center justify-center",
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "cost-arrow",
                                          attrs: {
                                            src: "/img/summoning/png/popup-summary-arrow@72x.png",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text--primary subtitle-2 pb-2",
                                      },
                                      [
                                        _vm.participantWizardIds.includes(
                                          participant.id
                                        )
                                          ? _c("span", [_vm._v("Soul -1x")])
                                          : _vm.participantLandId ===
                                            participant.id
                                          ? _c("span", [_vm._v("48h Cooldown")])
                                          : _c("span", [_vm._v("Burns 1x")]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "borderline" }),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "text--primary",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c("v-col", { staticClass: "py-2" }, [
                            _c("ol", { staticClass: "summary-list" }, [
                              !!_vm.participantLandId
                                ? _c("li", { staticClass: "subtitle-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.participants.land.data.wizarre
                                            .typeName
                                        ) +
                                        ": " +
                                        _vm._s(
                                          _vm.participants.land.data.description
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm.participantLandId
                                ? _c("li", { staticClass: "subtitle-2" }, [
                                    _vm._v(" Land: single use elixir "),
                                  ])
                                : _vm._e(),
                              _c(
                                "li",
                                { staticClass: "subtitle-2" },
                                [
                                  _vm._v(" Wizards relation: "),
                                  _vm.loadingSummaryPenalty
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.loadingSummaryPenalty &&
                                  _vm.summaryKinshipPenalty &&
                                  _vm.summaryKinshipPenalty.penalty > 0
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#a3569b" } },
                                          [_vm._v("Mutation")]
                                        ),
                                        _vm._v(
                                          " chance: " +
                                            _vm._s(
                                              _vm.summaryKinshipPenalty.penalty
                                            ) +
                                            "% "
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.loadingSummaryPenalty &&
                                  (null === _vm.summaryKinshipPenalty ||
                                    _vm.summaryKinshipPenalty.penalty === 0)
                                    ? _c("span", [_vm._v(" None ")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm.ingredientsWithoutSingleUseWizardLand.length > 0
                        ? _c("v-row", { attrs: { "no-gutters": "" } }, [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "font-basic text-h5 ingredient-header",
                              },
                              [_vm._v("Ingredients")]
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        _vm._l(
                          _vm.ingredientsWithoutSingleUseWizardLand,
                          function (item, i) {
                            return _c("mini-enhancement-slot", {
                              key: `enh_${i}: ${item.id}`,
                              attrs: { item: item.data },
                            })
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "text--primary",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c("v-col", { staticClass: "py-2" }, [
                            _c(
                              "ol",
                              { staticClass: "summary-list" },
                              _vm._l(
                                _vm.ingredientsWithoutSingleUseWizardLand,
                                function (item, i) {
                                  return _c(
                                    "li",
                                    { key: `item_${i}: ${item.id}` },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.data.effect) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-0", staticStyle: { "margin-bottom": "-8px" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "2px" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("v-col", { staticClass: "pb-2" }, [
                        _c("div", { staticClass: "borderline" }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pt-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex align-center ml-5" },
                        [
                          _c("price", {
                            attrs: { price: _vm.summonCost, "token-name": "" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "main-button",
                            {
                              staticClass: "mx-4 mr-md-5",
                              attrs: {
                                disabled: _vm.summoningDisabled,
                                loading:
                                  _vm.loadingSummaryPenalty || !_vm.summonCost,
                                "on-click": _vm.handleSummonClick,
                                big: "",
                              },
                            },
                            [_vm._v(" Summon ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            id: "SummonProgress",
            model: _vm.progressDialogOpen,
            width: "auto",
            persistent: "",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.progressDialogOpen = false
            },
          },
        },
        [
          _vm.summoningInProgress
            ? _c(
                "div",
                [
                  _c("h3", { staticClass: "text-center mb-8 mt-4" }, [
                    _vm._v("- Summoning in progress -"),
                  ]),
                  _c("v-progress-linear", {
                    staticClass: "mb-8",
                    attrs: { indeterminate: "", color: "golden" },
                  }),
                  _c("img", {
                    staticClass: "ritualImage",
                    staticStyle: { "margin-left": "-15px" },
                    attrs: {
                      src: "/img/summoning/png/ritual-of-summoning.png",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.morphingInProgress
            ? _c(
                "div",
                [
                  _c("h3", { staticClass: "text-center mb-4 mt-4" }, [
                    _vm._v("- Morphing in progress -"),
                  ]),
                  _vm.morphingNewWizardId
                    ? _c("h4", { staticClass: "text-center mb-6" }, [
                        _vm._v("Wizard #" + _vm._s(_vm.morphingNewWizardId)),
                      ])
                    : _vm._e(),
                  _c("v-progress-linear", {
                    staticClass: "mb-8",
                    attrs: { indeterminate: "", color: "golden" },
                  }),
                  _c("img", {
                    staticClass: "ritualImage rounded",
                    staticStyle: {
                      width: "581px",
                      height: "324px",
                      "margin-left": "-15px",
                    },
                    attrs: { src: "/img/summoning/png/morphing.png" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            id: "SummonConfirmation",
            model: _vm.confirmationDialogOpen,
            width: "auto",
            persistent: "",
          },
          on: {
            dialogClosed: function ($event) {
              return _vm.confirmationDialogClosed()
            },
          },
        },
        [
          _vm.summonedElemental !== null
            ? _c(
                "div",
                {
                  staticClass:
                    "text--primary d-flex flex-column align-center justify-space-between mt-3",
                },
                [
                  _c(
                    "h3",
                    { staticClass: "text-center golden--text mt-4 mb-4" },
                    [_vm._v("Congratulations!")]
                  ),
                  _c("img", {
                    staticClass: "elemental-avatar",
                    staticStyle: { width: "300px", height: "300px" },
                    attrs: { src: this.summonedElemental.thumbnail_300 },
                  }),
                  _c(
                    "span",
                    { staticClass: "caption text-center mb-4" },
                    [
                      _vm._v(" You've got a new "),
                      _vm._l(
                        _vm.summonedElemental.wizarre.elements,
                        function (elem, i) {
                          return _c(
                            "b",
                            {
                              key: `elem_${i}`,
                              staticClass: "text-capitalize",
                              class: elem,
                            },
                            [_vm._v(" " + _vm._s(elem) + " ")]
                          )
                        }
                      ),
                      _vm._v(
                        " Elemental #" + _vm._s(_vm.summonedElemental.id) + " !"
                      ),
                      _c("br"),
                      _vm._v(" It will grow into a Wizard in the "),
                      _c("b", [_vm._v("next 3 days")]),
                    ],
                    2
                  ),
                  _c("span", [_vm._v("You can check it on")]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: { name: "MyWizards", params: {} },
                        color: "purple",
                      },
                    },
                    [_vm._v(" My Wizards ")]
                  ),
                  _vm.summonedElemental.wizarre.land_power_user > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-10 pt-6 text-center land-triggered-information",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "golden--text text--black" },
                            [
                              _vm._v(
                                _vm._s(_vm.summonedElemental.wizarre.land_name)
                              ),
                            ]
                          ),
                          _vm._v(" has triggered during Ritual! "),
                          _c("br"),
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-caption text--secondary font-italic",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.summonedElemental.wizarre
                                    .land_power_description
                                )
                              ),
                            ]
                          ),
                          _c("img", {
                            staticClass: "portal-icon",
                            attrs: {
                              src: "/img/summoning/png/portal-icon@72x.png",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("div"),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            id: "ErrorConfirmation",
            model: _vm.errorDialogOpen,
            width: "auto",
            title: "Error occurred",
          },
          on: { dialogClosed: _vm.handleErrorDialogClose },
        },
        [
          _c("img", {
            staticClass: "error-image rounded",
            attrs: { src: "/img/summoning/png/error.png" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }