var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "overview full-size",
      class: {
        "overview-silhouette": !_vm.detailsScreen,
        "overview-details": _vm.detailsScreen,
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "overview-board", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "overview-control d-flex flex-column align-stretch",
            },
            [
              _c("div", {
                staticClass: "button button-square",
                class: _vm.buttonClass,
                on: {
                  click: function ($event) {
                    return _vm.handleUniversalButton()
                  },
                },
              }),
              _vm.isSomethingSelected && Boolean(_vm.elements)
                ? _c(
                    "div",
                    {
                      staticClass:
                        "element-container d-flex flex-column align-center",
                    },
                    _vm._l(_vm.elements, function (element, i) {
                      return _c("img", {
                        staticClass: "element-icon",
                        attrs: {
                          alt: `element_${i}`,
                          src: _vm.elementSrc(element),
                        },
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "button button-wing",
                  class: _vm.detailsScreen ? _vm.type : "details",
                },
                [_c("div", { staticClass: "lock" })]
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column justify-end align-center",
              attrs: { cols: "9" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "entity-name mt-1 mb-auto golden--text text-wrap text-caption text-md-subtitle-1 font-weight-bold",
                },
                [
                  _vm.isSomethingSelected
                    ? _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(_vm.entity.data.name)),
                      ])
                    : _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v(
                          "Select " + _vm._s(_vm.entity.type || "something")
                        ),
                      ]),
                ]
              ),
              _c("div", { staticClass: "d-flex flex-column align-center" }, [
                _vm.isSomethingSelected
                  ? _c("img", {
                      staticClass: "entity-image",
                      class: _vm.entity.type,
                      attrs: {
                        src:
                          _vm.entity.data.thumbnail_300 ||
                          _vm.entity.data.thumbnail ||
                          _vm.entity.data.image,
                      },
                    })
                  : _c("div", {
                      staticClass: "placeholder",
                      class: _vm.entity.type,
                    }),
                _c("div", { staticClass: "entity-foundation mb-4" }),
              ]),
            ]
          ),
          _c(
            "v-col",
            { staticClass: "d-flex flex-column align-stretch justify-start" },
            [
              _c("div", {
                staticClass: "button button-square search",
                on: {
                  click: function ($event) {
                    return _vm.goToEntities()
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-footer px-4", attrs: { "no-gutters": "" } },
        [
          _vm.isSomethingSelected && !!_vm.experience
            ? _c(
                "v-col",
                {
                  staticClass:
                    "text-no-wrap text-caption text-md-subtitle-2 d-flex align-center",
                  attrs: { cols: "5" },
                },
                [
                  _c("div", { staticClass: "pr-2" }, [
                    _vm._v("Lvl: " + _vm._s(_vm.experience.lvl)),
                  ]),
                  _c("v-progress-linear", {
                    staticClass: "elevation-6",
                    attrs: { value: 75, color: "white", height: "7.5" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.isSomethingSelected && !!_vm.souls
            ? _c(
                "v-col",
                {
                  staticClass:
                    "text-no-wrap text-caption text-md-subtitle-2 d-flex align-center",
                  attrs: { cols: "5" },
                },
                [
                  _c("div", { staticClass: "pr-2" }, [
                    _vm._v(
                      "Souls: " +
                        _vm._s(_vm.souls.current) +
                        "/" +
                        _vm._s(_vm.souls.max)
                    ),
                  ]),
                  _c("v-progress-linear", {
                    staticClass: "elevation-6",
                    attrs: {
                      value: _vm.soulsLeft,
                      color: "white",
                      height: "7.5",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }