<template>
    <div v-if="!isWalletConnected" class="pt-15">
        <connect-wallet></connect-wallet>
    </div>
    <div v-else-if="waitingForWizards || waitingForLands || loadingEnhancements"
         class="full-size d-flex flex-column justify-center align-center">
        <h3 class="text-center">- Loading Assets -</h3>
        <v-progress-circular
            size="100"
            indeterminate
            class="full-size mt-8"
        ></v-progress-circular>
    </div>
    <div v-else class="mt-4 d-flex flex-column align-center justify-start">
        <div>
            <div v-if="displayLands">
                <div v-if="0 === (lands.length + enhancementLands.length)">
                    <h3 class="text-center mt-10">- No Lands -</h3>
                </div>
                <div v-else class="d-flex flex-wrap justify-center px-2 px-md-6">
                    <div v-for="enhancementLand in filteredAndSortedEnhancementLands" :key="'le'+enhancementLand.id"
                         v-on:click="selectEntity({type: 'ingredient-land', data: enhancementLand})">
                        <mini-enhancement
                            class="ma-1"
                            :item="enhancementLand"
                        >
                        </mini-enhancement>
                    </div>
                    <div v-for="land in filteredAndSortedLands" :key="'l'+land.id"
                         v-on:click="selectLandEntity(land)"
                    >
                        <mini-land
                            class="ma-1"
                            :item="land"
                        ></mini-land>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div v-if="displayWizards">
                <div v-if="0 === (wizards.length + enchantmentWizards.length)">
                    <h3 class="text-center mt-10">- No Wizards -</h3>
                </div>
                <div v-else class="d-flex flex-wrap justify-center px-2 px-md-6">
                    <div v-for="enhancementWizard in filteredAndSortedEnchantmentWizards"
                         :key="'we'+enhancementWizard.id"
                         v-on:click="selectEntity({type: 'ingredient-wizard', data: enhancementWizard})">
                        <mini-enhancement
                            class="ma-1"
                            :item="enhancementWizard"
                        >
                        </mini-enhancement>
                    </div>
                    <div v-for="wizard in filteredAndSortedWizards" :key="'w'+wizard.id"
                         v-on:click="selectWizardEntity(wizard)"
                    >
                        <mini-wizard
                            class="ma-1"
                            :class="{'wizard-not-available': isWizardNotAvailable(wizard)}"
                            :title="quickWizardTooltip(wizard)"
                            v-title
                            :item="wizard"
                            :hasPenalty="wizardKinshipPenaltyIds.includes(wizard.id)"
                        ></mini-wizard>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div v-if="displayIngredients">
                <div v-if="0 === (filteredAndSortedIngredients.length)">
                    <h3 class="text-center mt-10">- No Supported Ingredients -</h3>
                </div>
                <div v-else class="d-flex flex-wrap justify-center px-2 px-md-6">
                    <div v-for="ingredient in filteredAndSortedIngredients" :key="'i'+ingredient.id"
                         v-on:click="selectEntity({type: 'ingredient', data: ingredient})">
                        <mini-enhancement
                            class="ma-1"
                            :item="ingredient"
                        ></mini-enhancement>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {apiServiceVue} from "@/services/apiService.vue";
import MiniWizard from "@/components/assets/wizards/mini-wizard";
import MiniLand from "@/components/assets/lands/mini-land";
import EnhancementContract from "@/services/contracts/enhancementContract";
import MiniEnhancement from "@/components/assets/enhancements/mini-enhancement";
import * as timeHelper from "../../helpers/timeHelper";
import ConnectWallet from "@/views/ConnectWallet";

export default {
    components: {ConnectWallet, MiniEnhancement, MiniLand, MiniWizard},
    props: {
        displayLands: Boolean,
        displayWizards: Boolean,
        displayIngredients: Boolean,
        participantWizardIds: Array,
        participantEnchantmentWizardIds: Array,
        participantLandId: Number,
        participantEnchantmentLandId: String,
        participantIngredientIds: Array,
    },
    data() {
        return {
            wizards: [],
            wizardKinshipPenaltyIds: [],
            lands: [],
            enchantmentWizards: [],
            enhancementLands: [],
            ingredients: [],
            loadingWizards: true,
            loadingLands: true,
            loadingEnhancements: true,
            enhancementContract: null,
        };
    },
    mounted: function () {
        if (this.isWalletConnected) {
            this.fetchMyWizards();
            this.fetchMyLands();
            this.fetchMyEnhancements();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchMyWizards();
                this.fetchMyLands();
                this.fetchMyEnhancements();
            });
        }
    },
    watch: {
        participantWizardIds: function (val, oldVal) {
            this.setKinshipPenaltyToWizards(val);
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        filteredAndSortedWizards() {
            const allWizards = this.wizards
                .filter(w => !this.participantWizardIds.includes(w.id));

            // Sort Wizards without Souls at last
            const soulWizards = allWizards
                .filter(w => 0 < w?.wizarre?.souls_remaining)
                .sort((a, b) => (a.id - b.id));
            const soullessWizards = allWizards
                .filter(w => 0 === w?.wizarre?.souls_remaining)
                .sort((a, b) => (a.id - b.id));

            // Add Wizards during loading
            const loadingWizards = allWizards.filter(w => !w.hasOwnProperty('wizarre'));

            return soulWizards.concat(soullessWizards, loadingWizards);
        },
        filteredAndSortedLands() {
            return this.lands
                .filter(l => l.id !== this.participantLandId)
                .sort((a, b) => (a.id - b.id));
        },
        filteredAndSortedEnchantmentWizards() {
            return this.enchantmentWizards
                .filter(i => i.amount > 0)
                .sort((a, b) => (a.id - b.id));
        },
        filteredAndSortedEnhancementLands() {
            return this.enhancementLands
                .filter(i => i.amount > 0)
                .sort((a, b) => (a.id - b.id));
        },
        filteredAndSortedIngredients() {
            return this.ingredients
                .filter(i => i.amount > 0)
                .sort((a, b) => (a.id - b.id));
        },
        waitingForWizards() {
            return this.loadingWizards && this.displayWizards;
        },
        waitingForLands() {
            return this.loadingLands && this.displayLands;
        },
    },
    methods: {
        async fetchMyWizards() {
            this.loadingWizards = true;
            this.wizards = [];

            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'wallet/' + this.web3.address;
            apiServiceVue.get(url, {limit: 500, offset: 0})
                .then(res => {
                    const wizards = res.data;
                    if (wizards && wizards.length > 0) {
                        this.wizards = wizards;
                    }
                    this.loadingWizards = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Wizards failed'
                    });
                });
            this.loadingWizards = false;
        },
        fetchMyLands() {
            this.loadingLands = true;
            this.lands = [];

            const url = process.env.VUE_APP_LAND_NFT_SERVER_URL + 'wallet/' + this.web3.address;
            apiServiceVue.get(url, {limit: 500, offset: 0})
                .then(res => {
                    const lands = res.data;
                    if (lands && lands.length > 0) {
                        this.lands = lands;
                    }
                    this.loadingLands = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Lands failed'
                    });
                });
        },
        async fetchMyEnhancements() {
            this.enhancementContract = new EnhancementContract(this.web3);
            this.loadingEnhancements = true;
            this.enchantmentWizards = [];
            this.enhancementLands = [];
            this.ingredients = [];

            this.enhancementContract.getMyBalanceOfEnhancementsBatch()
                .then((res) => {
                    let ids = [];
                    res.forEach((amount, id) => {
                        if (amount > 0) {
                            ids.push(id);
                        }
                    });
                    if (ids.length > 0) {
                        const url = process.env.VUE_APP_ENHANCEMENT_NFT_SERVER_URL + ids;
                        let enchantmentWizards = [];
                        let enchantmentLands = [];
                        let ingredients = [];
                        apiServiceVue.request(url).then((res2) => {
                            ids.forEach((id) => {
                                // empty item (show anything in case missing in DB)
                                let enh = {
                                    id: id,
                                    name: '#' + id,
                                    description: '?',
                                    img: ''
                                };
                                if (res2.hasOwnProperty(id)) {
                                    enh = res2[id];
                                    enh.amount = res[id];
                                }
                                if ('ingredient-wizard' === enh.type) {
                                    enchantmentWizards.push(enh)
                                }
                                if ('ingredient-land' === enh.type) {
                                    enchantmentLands.push(enh)
                                }
                                if ('ingredient' === enh.type) {
                                    ingredients.push(enh)
                                }
                            });
                        });
                        this.enchantmentWizards = enchantmentWizards
                        this.enhancementLands = enchantmentLands
                        this.ingredients = ingredients
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingEnhancements = false;
                });
        },
        selectLandEntity(land) {
            if ('undefined' !== typeof land.wizarre && land.wizarre.cooldown_timestamp) {
                if (!timeHelper.isUnixTimestampPassed(land.wizarre.cooldown_timestamp)) {
                    this.flashMessage.show({
                        status: 'warning',
                        message: 'This Land is still recharging'
                    });
                    return;
                }
            }

            this.selectEntity({type: 'land', data: land});
        },
        selectWizardEntity(wizard) {
            if (!wizard.hasOwnProperty('wizarre')) {
                this.flashMessage.show({
                    status: 'warning',
                    message: 'Wizard is still loading'
                });
                return;
            }

            if (0 === this.soulsRemaining(wizard)) {
                this.flashMessage.show({
                    status: 'warning',
                    message: 'This Wizard have no available souls'
                });
                return;
            }

            if (this.isNotYetMatured(wizard)) {
                this.flashMessage.show({
                    status: 'warning',
                    message: 'This Wizard is not matured yet'
                });
                return;
            }

            return this.selectEntity({type: 'wizard', data: wizard});
        },
        soulsRemaining(wizard) {
            return wizard?.wizarre?.souls_remaining;
        },
        isNotYetMatured(wizard) {
            if (wizard.wizarre?.mature_on) {
                return !timeHelper.isDbDatePassed(wizard.wizarre.mature_on);
            }
            return false;
        },
        isWizardNotAvailable(wizard) {
            const souls = (0 === this.soulsRemaining(wizard));
            const matured = this.isNotYetMatured(wizard)
            return souls || matured;
        },
        selectEntity({type, data}) {
            this.$emit('select-entity', {type: type, data: data})
        },
        setKinshipPenaltyToWizards(wizardIds) {
            this.wizardKinshipPenaltyIds = [];
            for (const wizardId of wizardIds) {
                const url = process.env.VUE_APP_WIZARD_RELATIVES_URL + wizardId;
                apiServiceVue.request(url).then(relatives => {
                    if (relatives) {
                        Object.entries(relatives).forEach(([name, group]) => {
                            group.forEach((wizId) => {
                                this.wizardKinshipPenaltyIds.push(wizId);
                            });
                        })
                    }
                });
            }
        },
        quickWizardTooltip(wizard) {
            if (wizard.wizarre?.mature_on) {
                return 'Mature on: ' + (new Date(wizard.wizarre.mature_on)).toLocaleString();
            }
            if (0 === wizard.wizarre?.souls_remaining) {
                return 'No souls remaining';
            }

            return null;
        },
    }
}
</script>

<style lang="scss" scoped>
.wizard-not-available {
    opacity: 0.5;
}
</style>
