<template>
    <div :class="{'small':small}">
        <img src="/img/scroll_48.png"/>
        <v-progress-circular
            v-if="price === null && !displayPlaceholder"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <span v-if="displayPlaceholder">
            &nbsp;-&nbsp;
        </span>
        <span v-if="price !== null && !displayPlaceholder">
            {{ price | localFormatNumber }}
        </span>
        <span v-if="tokenName">
            SCRL
        </span>
    </div>
</template>

<script>
export default {
    props: {
        price: Number | BigInt,
        tokenName: Boolean,
        displayPlaceholder: Boolean,
        small: Boolean,
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {}
};
</script>

<style lang="scss" scoped>
div {
    display: inline-flex;
    align-items: center;
}

span {
    font-size: 30px;
    margin: 0 0 0 5px;
}

img {
    width: 32px;
    display: inline-block;
}

.small {
    img {
        width: 16px;
    }

    span {
        font-size: 20px;
    }
}
</style>
