var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "full-size d-flex flex-row flex-md-column justify-space-around align-center",
    },
    _vm._l(_vm.ingredients, function (ingredient, i) {
      return _c(
        "div",
        {
          key: "i" + i,
          staticClass: "slot fullBgSize",
          class: { selected: ingredient.selected },
          on: {
            click: function ($event) {
              return _vm.selectSlot(i)
            },
          },
        },
        [
          !ingredient.data
            ? _c("div", {
                staticClass: "fullBgSize full-size add-to-empty-sign",
              })
            : _c("img", {
                staticClass: "ingredient-img",
                attrs: { src: ingredient.data.image },
              }),
          _vm.shouldLockSlot(ingredient)
            ? _c("div", {
                staticClass: "lock",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              })
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }