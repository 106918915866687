<template>
    <div class="full-size d-flex flex-row flex-md-column justify-space-around align-center">
        <div v-for="(ingredient, i) in ingredients" :key="'i'+i"
             class="slot fullBgSize"
             :class="{'selected':ingredient.selected}"
             v-on:click="selectSlot(i)"
        >
            <div v-if="!ingredient.data" class="fullBgSize full-size add-to-empty-sign"></div>
            <img v-else :src="ingredient.data.image" class="ingredient-img"/>
            <div v-if="shouldLockSlot(ingredient)" class="lock" v-on:click.stop></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ingredients: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            slots: [
                null, null, null
            ],
        }
    },
    mounted() {
    },
    methods: {
        shouldLockSlot(ingredient) {
            const lockingIngredientsIds = [0, 1];
            return ingredient.data && ingredient.data.id && lockingIngredientsIds.includes(parseInt(ingredient.data.id));
        },
        selectSlot(i) {
            this.$emit('select-participant', {type: "ingredient", data: this.ingredients[i].data, no: i})
        },
    },
};
</script>

<style lang="scss" scoped>
$imgPath: "/img/summoning/png";

.fullBgSize {
    background-size: 100% 100%;
}

.add-to-empty-sign {
    background-image: url('#{$imgPath}/niche-plus@72x.png');
}

.slot {
    position: relative;
    height: 5.5vw;
    width: 5.5vw;
    cursor: pointer;
    background-image: url('#{$imgPath}/niche@72x.png');

    &:hover {
        background-image: url('#{$imgPath}/niche@72x.png'), url('#{$imgPath}/niche-hoover@72x.png');
    }

    &.selected {
        background-image: url('#{$imgPath}/niche-selected@72x.png');

        &:hover {
            background-image: url('#{$imgPath}/niche-selected@72x.png'), url('#{$imgPath}/niche-hoover@72x.png');
        }
    }
}

.ingredient-img {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
}

.lock {
    background-image: url("#{$imgPath}/padlock@72x.png");
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
}

@media only screen and (max-width: $sm) {
    .slot {
        height: 10vw;
        width: 10vw;
        min-height: 50px;
        min-width: 50px;
    }
}

@media only screen and (max-width: $xs) {
    .slot {
        height: 15vw;
        width: 15vw;
    }
}
</style>
