<template>
    <v-container class="overview full-size"
                 :class="{'overview-silhouette': !detailsScreen, 'overview-details': detailsScreen}">
        <v-row no-gutters class="overview-board">
            <v-col class="overview-control d-flex flex-column align-stretch">
                <div class="button button-square" :class="buttonClass"
                     v-on:click="handleUniversalButton()"/>
                <div v-if="isSomethingSelected && Boolean(elements)"
                     class="element-container d-flex flex-column align-center">
                    <img v-for="(element, i) in elements" class="element-icon"
                         :alt="`element_${i}`" :src=" elementSrc(element)"/>
                </div>
                <div class="button button-wing" :class="(detailsScreen?type:'details')">
                    <div class="lock"/>
                </div>
            </v-col>

            <v-col cols="9" class="d-flex flex-column justify-end align-center">
                <div
                    class="entity-name mt-1 mb-auto golden--text text-wrap text-caption text-md-subtitle-1 font-weight-bold">
                    <span v-if="isSomethingSelected" class="text-capitalize">{{ entity.data.name }}</span>
                    <span v-else class="text-capitalize">Select {{ entity.type || 'something' }}</span>
                </div>
                <div class="d-flex flex-column align-center">
                    <img v-if="isSomethingSelected" class="entity-image" :class="entity.type"
                         :src="entity.data.thumbnail_300 || entity.data.thumbnail || entity.data.image"/>
                    <div v-else class="placeholder" :class="entity.type"/>
                    <div class="entity-foundation mb-4"></div>
                </div>
            </v-col>

            <v-col class="d-flex flex-column align-stretch justify-start">
                <div class="button button-square search" v-on:click="goToEntities()"/>
            </v-col>
        </v-row>
        <v-row no-gutters class="overview-footer px-4">
            <v-col v-if="isSomethingSelected && !!experience" cols="5"
                   class="text-no-wrap text-caption text-md-subtitle-2 d-flex align-center">
                <div class="pr-2">Lvl: {{ experience.lvl }}</div>
                <v-progress-linear :value="75" color="white" height="7.5" class="elevation-6"/>
            </v-col>
            <v-spacer/>
            <v-col v-if="isSomethingSelected && !!souls" cols="5"
                   class="text-no-wrap text-caption text-md-subtitle-2 d-flex align-center">
                <div class="pr-2">Souls: {{ souls.current }}/{{ souls.max }}</div>
                <v-progress-linear :value="soulsLeft" color="white" height="7.5" class="elevation-6"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'

export default {
    props: {
        buttonClass: String,
        entity: Object,
    },
    data() {
        return {
            detailsScreen: false,
        };
    },
    methods: {
        elementSrc(name) {
            return `img/icons/element/256/${name}.png`
        },
        handleUniversalButton() {
            this.$emit('universal-button-click')
        },
        goToEntities() {
            //scrolls UI
            goTo('.entities-container')
        },
    },
    computed: {
        isSomethingSelected() {
            return Boolean(this?.entity?.data?.id);
        },
        elements() {
            return this.entity.data?.wizarre?.elements?.filter(Boolean);
        },
        souls() {
            const data = this.entity.data;
            return !!data?.wizarre?.souls_remaining && data?.wizarre?.souls_max
                ? {
                    current: data.wizarre.souls_remaining,
                    max: data.wizarre.souls_max,
                }
                : null;
        },
        experience() {
            const data = this.entity?.data?.wizarre;
            return data?.hasOwnProperty('experience')
                ? {
                    progress: data.experience % 1000,
                    lvl: Math.floor(data.experience / 1000),
                }
                : null;
        },
        soulsLeft() {
            return (this.souls.current / this.souls.max) * 100;
        },

    }
}
</script>

<style lang="scss" scoped>
$overview-height: 90%;

@function bgSrc($name) {
    $path: "/img/summoning/png/" + $name;
    @return url($path);
}

.overview {
    background-size: 100% 100%;
    padding: 4% 0.5%;
}

.overview-silhouette {
    background-image: bgSrc('wizard-bg-wide@72x.png');
}

.overview-details {
    background-image: bgSrc('wizard-stat-bg-wide@72x.png');
}

.overview-control {
    overflow: hidden;
}

.overview-board {
    height: $overview-height;
}

.overview-footer {
    height: calc(100% - #{$overview-height});
}

.button {
    cursor: pointer;
    background-size: 100% 100%;
}

.button-square {
    padding-top: 100%;

    &.remove {
        background-image: bgSrc('button-minus@72x.png');

        &:hover {
            background-image: bgSrc('button-minus-hoover@72x.png');
        }
    }

    &.add {
        background-image: bgSrc('button-plus@72x.png');

        &:hover {
            background-image: bgSrc('button-plus-hoover@72x.png');
        }
    }

    &.replace {
        background-image: bgSrc('button-replace@72x.png');

        &:hover {
            background-image: bgSrc('button-replace-hoover@72x.png');
        }
    }

    &.search {
        background-image: bgSrc('button-search@72x.png');

        &:hover {
            background-image: bgSrc('button-search-hoover@72x.png');
        }
    }
}

.element-icon {
    width: 60%;
}

$button-wing-offset: 15%;
.button-wing {
    cursor: default;
    margin: auto $button-wing-offset 5% calc(#{$button-wing-offset} * -1);
    padding-top: 119.2%;

    &.details, &.anything {
        transform: scaleX(-1);
        background-image: bgSrc('button-stats@72x.png');

        &:hover {
            background-image: bgSrc('button-stats-hoover@72x.png');
        }
    }

    &.wizard {
        background-image: bgSrc('button-wizard@72x.png');

        &:hover {
            background-image: bgSrc('button-wizard-hoover@72x.png');
        }
    }

    &.land {
        background-image: bgSrc('button-portal@72x.png');

        &:hover {
            background-image: bgSrc('button-portal-hoover@72x.png');
        }
    }
}

.entity-image {
    max-height: 13.5vw;
    z-index: 1;

    &.ingredient-wizard,
    &.ingredient-land {
        max-height: 11vw;
    }

    &.land {
        margin-bottom: -4%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.entity-foundation {
    width: 80%;
    height: 2vw;
    margin-top: -1.5vw;
    background-color: #3a2949;
    border-radius: 50%;
}

.placeholder {
    background-size: 100% 100%;
    z-index: 1;

    &.wizard {
        background-image: bgSrc('wizard@72x.png');
        width: 10vw;
        padding-top: 143.5%;
        margin-bottom: -0.75vw;
    }

    &.land {
        background-image: bgSrc('portal@72x.png');
        width: 12vw;
        padding-top: 116.8%;
        margin-bottom: -0.5vw;
    }
}

.lock {
    background-image: bgSrc('padlock@72x.png');
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 12.5%;
    width: 80%;
    height: 100%;
    cursor: default;
}

@media only screen and (max-width: $sm) {
    .entity-image {
        max-height: 22vw;
    }

    .placeholder {
        &.wizard {
            background-image: bgSrc('wizard@72x.png');
            width: 15vw;
            padding-top: 143.5%;
            margin-bottom: -0.75vw;
        }

        &.land {
            background-image: bgSrc('portal@72x.png');
            width: 19vw;
            padding-top: 116.8%;
            margin-bottom: -0.5vw;
        }
    }

    .element-icon {
        width: 80%;
    }
}

@media only screen and (max-width: $xs) {
    .entity-image {
        max-height: 36vw;
    }

    .placeholder {
        &.wizard {
            background-image: bgSrc('wizard@72x.png');
            width: 25vw;
            padding-top: 143.5%;
            margin-bottom: -0.75vw;
        }

        &.land {
            background-image: bgSrc('portal@72x.png');
            width: 30vw;
            padding-top: 116.8%;
            margin-bottom: -0.5vw;
        }
    }

    .entity-foundation {
        height: 5vw;
        margin-top: -4.75vw;
    }
}
</style>
