var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-size d-flex flex-column align-stretch justify-end" },
    [
      _c(
        "div",
        {
          staticClass:
            "name text-capitalize mb-auto golden--text text-center text-wrap text-caption text-md-subtitle-1 font-weight-bold",
        },
        [_vm._v(" " + _vm._s(_vm.name) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column align-center align-content-end",
          class: { mirrored: _vm.mirror },
        },
        [
          _vm.isParticipantDefined && Boolean(_vm.elements)
            ? _c(
                "div",
                {
                  staticClass:
                    "pt-2 pl-1 pos-absolute align-self-baseline d-flex flex-column",
                },
                _vm._l(_vm.elements, function (element, i) {
                  return _c("img", {
                    staticClass: "element-icon",
                    class: { mirrored: _vm.mirror },
                    attrs: {
                      alt: `element_${i}`,
                      src: _vm.elementSrc(element),
                    },
                  })
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "arrow-container d-flex flex-column align-center justify-center",
            },
            [
              _vm.selected || _vm.hover
                ? _c("img", {
                    staticClass: "arrow d-flex",
                    class: { bounce: _vm.selected },
                    attrs: { src: _vm.imgSrc("arrow") },
                  })
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "hero d-flex flex-column justify-end" }, [
            _vm.isParticipantDefined
              ? _c("img", {
                  staticClass: "main-image",
                  class: {
                    "main-image-smaller": [
                      "ingredient-wizard",
                      "ingredient-land",
                    ].includes(_vm.participant.type),
                  },
                  attrs: {
                    src:
                      _vm.participant.thumbnail_300 ||
                      _vm.participant.thumbnail ||
                      _vm.participant.image,
                  },
                })
              : _c("div", [
                  _c("div", {
                    staticClass: "placeholder",
                    style: _vm.placeholderImage(),
                  }),
                ]),
          ]),
          _c("div", {
            staticClass: "foundation",
            class: {
              "foundation--selected": _vm.selected,
              "foundation--hover": _vm.hover,
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "details d-flex align-center justify-space-between" },
        [
          _vm.isParticipantDefined && Boolean(_vm.souls)
            ? _c(
                "div",
                {
                  staticClass:
                    "full-size text-center px-2 px-md-4 pt-1 text-caption text-md-subtitle-2",
                },
                [
                  _vm._v(
                    " Soul: " +
                      _vm._s(_vm.souls.current) +
                      "/" +
                      _vm._s(_vm.souls.max) +
                      " "
                  ),
                  _c("v-progress-linear", {
                    attrs: { value: _vm.soulsLeft, color: "white" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }