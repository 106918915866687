var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-container d-flex flex-column align-stretch mb-4" },
    [
      _c(
        "div",
        {
          staticClass:
            "dashboard-container d-flex flex-column flex-sm-row align-stretch flex-grow-1 flex-shrink-0",
        },
        [
          _c("div", { staticClass: "dashboard-spacer" }),
          _c(
            "div",
            {
              staticClass:
                "container-off-selected d-flex flex-column flex-md-row justify-space-between align-sm-stretch flex-grow-0 flex-md-grow-1",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ritual-container mx-auto px-a py-1 flex-grow-1 flex-md-grow-0",
                },
                [
                  _c("summoning-ritual", {
                    attrs: { participants: _vm.participants },
                    on: { "select-participant": _vm.handleSelectParticipant },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ingredients-container flex-grow-0 flex-md-grow-1",
                },
                [
                  _c("summoning-ingredients", {
                    attrs: { ingredients: _vm.participants.ingredients },
                    on: { "select-participant": _vm.handleSelectParticipant },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "details-container mx-auto px-2" },
            [
              _c("summoning-details-overview", {
                attrs: {
                  entity: _vm.selectedEntity,
                  "button-class": _vm.universalButtonClass,
                },
                on: {
                  "universal-button-click": function ($event) {
                    return _vm.handleUniversalButtonClick()
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "dashboard-spacer" }),
        ]
      ),
      _c(
        "div",
        { staticClass: "summon-container" },
        [
          _c("summoning-summon-controls", {
            attrs: {
              participants: _vm.participants,
              "participant-land-id": _vm.participantLandId,
              "participant-enchantment-land-id":
                _vm.participantEnchantmentLandId,
              "participant-wizard-ids": _vm.participantWizardIds,
              "participant-enchantment-wizard-ids":
                _vm.participantEnchantmentWizardIds,
              "participant-ingredient-ids": _vm.participantIngredientIds,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "entities-container" },
        [
          _c("summoning-entities-browser", {
            attrs: {
              "display-lands": _vm.selectedParticipantType === "land",
              "display-wizards": _vm.selectedParticipantType === "wizard",
              "display-ingredients":
                _vm.selectedParticipantType === "ingredient",
              "participant-land-id": _vm.participantLandId,
              "participant-enchantment-land-id":
                _vm.participantEnchantmentLandId,
              "participant-wizard-ids": _vm.participantWizardIds,
              "participant-enchantment-wizard-ids":
                _vm.participantEnchantmentWizardIds,
              "participant-ingredient-ids": _vm.participantIngredientIds,
            },
            on: { "select-entity": _vm.handleSelectEntity },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }