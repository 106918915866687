<template>
    <div class="full-size d-flex flex-column align-stretch justify-end">
        <div
            class="name text-capitalize mb-auto golden--text text-center text-wrap text-caption text-md-subtitle-1 font-weight-bold">
            {{ name }}
        </div>
        <div class="d-flex flex-column align-center align-content-end" :class="{mirrored: mirror}">
            <div v-if="isParticipantDefined && Boolean(elements)"
                 class="pt-2 pl-1 pos-absolute align-self-baseline d-flex flex-column">
                <img v-for="(element, i) in elements"
                     :alt="`element_${i}`" :src="elementSrc(element)"
                     class="element-icon"
                     :class="{mirrored: mirror}"/>
            </div>
            <div class="arrow-container d-flex flex-column align-center justify-center">
                <img v-if="selected || hover" class="arrow d-flex"
                     :class="{bounce: selected}" :src="imgSrc('arrow')" />
            </div>
            <div class="hero d-flex flex-column justify-end">
                <img v-if="isParticipantDefined" class="main-image"
                     :class="{'main-image-smaller': ['ingredient-wizard','ingredient-land'].includes(participant.type)}"
                     :src="participant.thumbnail_300 || participant.thumbnail || participant.image"
                />
                <div v-else>
                    <div class="placeholder" :style="placeholderImage()"/>
                </div>
            </div>
            <div class="foundation" :class="{ 'foundation--selected': selected, 'foundation--hover': hover}"/>
        </div>
        <div class="details d-flex align-center justify-space-between">
            <div v-if="isParticipantDefined && Boolean(souls)"
                 class="full-size text-center px-2 px-md-4 pt-1 text-caption text-md-subtitle-2"
            >
                Soul: {{ souls.current }}/{{ souls.max }}
                <v-progress-linear :value="soulsLeft" color="white"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selected: Boolean,
        hover: Boolean,
        placeholderName: String,
        participant: Object,
        mirror: Boolean,
    },
    methods: {
        imgSrc(name) {
            return `/img/summoning/png/${name}@72x.png`
        },
        elementSrc(name) {
            return `/img/icons/element/32/${name}.png`
        },
        placeholderImage() {
            const grayedImg = this.placeholderName;
            const selectedName = grayedImg + '-selected';
            const hooverName = grayedImg + '-hoover';
            const images = [`url(${this.imgSrc(this.selected ? selectedName : grayedImg)})`];
            if (this.hover) images.push(`url(${this.imgSrc(hooverName)})`);
            return `background-image: ${images.join(', ')};`
        },
    },
    computed: {
        name() {
            return this.isParticipantDefined
                ? this.participant.name
                : this.placeholderName;
        },
        isParticipantDefined() {
            return Boolean(this.participant?.name);
        },
        elements() {
            return this.participant?.wizarre?.elements?.filter(Boolean);
        },
        souls() {
            const remaining = this.participant?.wizarre?.souls_remaining;
            const max = this.participant?.wizarre?.souls_max;
            return remaining !== undefined && max !== undefined
                ? {
                    current: remaining,
                    max: max,
                }
                : null;
        },
        soulsLeft() {
            return (this.souls.current / this.souls.max) * 100;
        },
    }
}
</script>

<style lang="scss" scoped>

.name {
    z-index: 2;
}

.element-icon {
    width: 3vw;
    z-index: 10;
}

.arrow-container {
    height: 5vw;
    margin-bottom: -3vw;
    margin-top: -1vw
}

.arrow {
    z-index: 2;
    height: 100%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.bounce {
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

.hero {
    z-index: 1;
    min-height: 14vw;
    cursor: pointer;
}

.main-image {
    max-height: 12vw;
}

.main-image-smaller {
    max-height: 9vw;
}

.placeholder {
    background-position: center;
    background-size: auto 100%;
    width: 10vw;
    height: 13vw;
}

.foundation {
    width: 80%;
    height: 2.5vw;
    margin-top: -2.25vw;
    background-color: #1c262f;
    border-width: 2px;
    border-radius: 50%;
    border-color: #facf1a;
    border-style: hidden;
    box-shadow: none;
}

.foundation--selected {
    border-style: solid;
}

.foundation--hover {
    box-shadow: 0 0 4px 2px rgba(253, 253, 254, 1);
}

.details {
    min-height: 30px;
}

.mirrored {
    transform: scaleX(-1);
}


@media only screen and (max-width: $sm) {
    .hero {
        min-height: 14vw;
    }

    .main-image {
        max-height: 14vw;
    }

    .main-image-smaller {
        max-height: 10vw;
    }

    .placeholder {
        width: 10vw;
        height: 14vw;
    }

    .arrow-container {
        margin-top: -2.5vw
    }
}


@media only screen and (max-width: $xs) {
    .hero {
        min-height: 33vw;
    }

    .main-image {
        max-height: 33vw;
    }

    .main-image-smaller {
        max-height: 25vw;
    }

    .placeholder {
        width: 25vw;
        height: 32.5vw;
    }

    .arrow-container {
        margin-top: -1vw;
        margin-bottom: -6vw;
        height: 10vw;
    }

    .foundation {
        height: 5vw;
        margin-top: -4.75vw;
    }

    .element-icon {
        width: 6vw;
    }
}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.25, .75) translateY(-1vmin);
    }
    30% {
        transform: scale(.8, 1.2) translateY(2vmin);
    }
    45% {
        transform: scale(1.1, .90) translateY(-0.5vmin);
    }
    60% {
        transform: scale(.95, 1.05) translateY(0.5vmin);
    }
    70% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}
</style>
