<template>
    <div class="w-100 pa-1 pb-3 d-flex flex-wrap align-center justify-center">
        <div class="w-100 d-flex flex-column flex-sm-row align-center justify-center">
            <div class="px-2 pt-1 mx-8 d-flex flex-column align-center justify-center bottom-btn">
                <span class="text-subtitle-2"
                      v-if="allowances.filter((a)=>{return a.shouldDisplay()}).length>0"
                >
                    Required allowances:
                </span>
                <div class="allowance-buttons d-flex flex-column flex-md-row">
                    <main-button
                        v-for="(allowance, i) in allowances" :key="`allowance_${i}: ${allowance.name}`"
                        :class="{disabled: allowance.allowed || !allowance.checked}"
                        :disabled="allowance.allowed || !allowance.checked"
                        :loading="!allowance.checked || allowance.loading"
                        :on-click="()=>handleApproveRequestClick(allowance)"
                        v-if="allowance.shouldDisplay()"
                    >
                        <v-icon v-if="allowance.allowed" size="small">mdi-check-outline</v-icon>
                        <v-icon v-else size="small">mdi-alert-circle-outline</v-icon>
                        {{ allowance.name }}
                    </main-button>
                </div>
            </div>
            <div class="d-flex flex-column-reverse flex-md-row align-center">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <main-button
                                :disabled="summoningDisabled"
                                :on-click="openSummary"
                                big
                                glow
                            >
                                Summon
                            </main-button>
                        </div>
                    </template>
                    <div>
                        <span v-if="!summoningDisabled">Click to open confirmation window</span>
                        <ul v-else>
                            <li v-for="(warning, i) in summoningWarnings" :key="'warn-' + i">{{ warning }}</li>
                        </ul>
                    </div>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <div class="summon-cost-box d-flex flex-row">
                                <div class="bottom-btn">
                                    <price :price="summonCost" :display-placeholder="null===summonCost"
                                           token-name></price>
                                </div>
                                <v-icon style="margin: -6px 0 0 -10px">mdi-help-circle-outline</v-icon>
                            </div>
                        </div>
                    </template>
                    <div>
                        <h4 class="mt-2 mb-2">Summoning cost</h4>
                        <p>Depends on remaining souls amount of participating Wizards</p>
                        <v-data-table
                            class="mb-3"
                            :headers="[
                                {text:'Remaining souls', value:'souls',width:150, align:'end'},
                                {text:'Cost per Wizard', value:'cost'},
                            ]"
                            :items="[
                                {'souls':5, cost:summonCostsFromApi[4]},
                                {'souls':4, cost:summonCostsFromApi[3]},
                                {'souls':3, cost:summonCostsFromApi[2]},
                                {'souls':2, cost:summonCostsFromApi[1]},
                                {'souls':1, cost:summonCostsFromApi[0]},
                                ]"
                            dense
                            hide-default-footer
                        >
                            <template v-slot:item.cost="{ item }">
                                <price :price="item.cost" small :displayPlaceholder="!item.cost"></price>
                            </template>
                        </v-data-table>

<!--                        <table border="1">-->
<!--                            <tr>-->
<!--                                <td>Remaining souls</td>-->
<!--                                <td>Cost per Wizard</td>-->
<!--                            </tr>-->
<!--                            <tr v-for="n in [5,4,3,2,1]">-->
<!--                                <td>{{ n }} Soul</td>-->
<!--                                <td>-->
<!--                                    <price :price="summonCostsFromApi[n-1]"></price>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                        </table>-->
                    </div>
                </v-tooltip>
            </div>
        </div>

        <dialog-box id="SummonSummary"
                    :model="summaryDialogOpen"
                    title="Ritual Summary"
                    remove-v-card-wrap
                    v-on:dialogClosed="summaryDialogOpen=false"
                    width="800px"
        >
            <v-card-text>
                <v-container v-if="participantsValid" class="fill-height ma-0 pa-0">
                    <v-row no-gutters class="mt-5">
                        <v-col
                            v-for="(participant, i) in [participants.wizards[0], participants.land, participants.wizards[1]].map(p => p.data)"
                            :key="'part'+i"
                            :class="{'wizard-column': i !== 1}"
                            :order="i===1 ? 4 : i"
                            :order-sm="i"
                        >
                            <div class="borderline"/>
                            <div
                                class="d-flex text-center flex-column align-center justify-space-between">
                                    <span
                                        class="subtitle-1 pt-2 font-weight-bold golden--text summary-participant-name">
                                        {{ participant.name }}
                                    </span>
                                <img
                                    :src="getImage(participant)"
                                    class="dialog--participant-image" :class="{mirrored: i===2}"/>
                                <div class="text--primary">
                                        <span v-if="haveSoul(participant)">
                                            Souls:
                                            {{ participant.wizarre.souls_remaining }}
                                            /
                                            {{ participant.wizarre.souls_max }}
                                        </span>
                                    <span
                                        v-if="participant.hasOwnProperty('wizarre') && participant.wizarre.typeName">
                                            {{ participant.wizarre.typeName }}
                                        </span>
                                    <span v-else>&nbsp;</span>
                                </div>
                                <div class="borderline my-4 d-flex align-center justify-center">
                                    <img src="/img/summoning/png/popup-summary-arrow@72x.png" class="cost-arrow">
                                </div>
                                <div class="text--primary subtitle-2 pb-2">
                                    <span v-if="participantWizardIds.includes(participant.id)">Soul -1x</span>
                                    <span v-else-if="participantLandId === participant.id">48h Cooldown</span>
                                    <span v-else>Burns 1x</span>
                                </div>
                            </div>
                            <div class="borderline"/>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="text--primary">
                        <v-col class="py-2">
                            <ol class="summary-list">
                                <li v-if="!!participantLandId" class="subtitle-2">
                                    {{ participants.land.data.wizarre.typeName }}:
                                    {{ participants.land.data.description }}
                                </li>
                                <li v-if="!participantLandId" class="subtitle-2">
                                    Land: single use elixir
                                </li>
                                <li class="subtitle-2">
                                    Wizards relation:
                                    <v-progress-circular
                                        v-if="loadingSummaryPenalty"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                    <span
                                        v-if="!loadingSummaryPenalty && summaryKinshipPenalty && summaryKinshipPenalty.penalty>0"
                                    >
                                            <span style="color:#a3569b">Mutation</span>
                                            chance: {{ summaryKinshipPenalty.penalty }}%
                                        </span>
                                    <span
                                        v-if="!loadingSummaryPenalty && (null === summaryKinshipPenalty || summaryKinshipPenalty.penalty===0)"
                                    >
                                            None
                                        </span>
                                </li>
                            </ol>
                        </v-col>
                    </v-row>

                    <v-row no-gutters v-if="ingredientsWithoutSingleUseWizardLand.length>0">
                        <h3 class="font-basic text-h5 ingredient-header">Ingredients</h3>
                    </v-row>
                    <v-row no-gutters>
                        <mini-enhancement-slot
                            v-for="(item, i) in ingredientsWithoutSingleUseWizardLand"
                            :key="`enh_${i}: ${item.id}`"
                            :item="item.data"
                        ></mini-enhancement-slot>
                    </v-row>
                    <v-row no-gutters class="text--primary">
                        <v-col class="py-2">
                            <ol class="summary-list">
                                <li v-for="(item, i) in ingredientsWithoutSingleUseWizardLand"
                                    :key="`item_${i}: ${item.id}`">
                                    {{ item.data.effect }}
                                </li>
                            </ol>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="pb-0" style="margin-bottom: -8px">
                <v-container class="pa-0">
                    <v-row no-gutters style="height:2px">
                        <v-col class="pb-2">
                            <div class="borderline"/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-2">
                        <v-col class="d-flex align-center ml-5">
                            <price :price="summonCost" token-name></price>
                        </v-col>
                        <v-col cols="auto">
                            <main-button
                                class="mx-4 mr-md-5"
                                :disabled="summoningDisabled"
                                :loading="loadingSummaryPenalty || !summonCost"
                                :on-click="handleSummonClick"
                                big
                            >
                                Summon
                            </main-button>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </dialog-box>

        <dialog-box id="SummonProgress" :model="progressDialogOpen"
                    width="auto"
                    v-on:dialogClosed="progressDialogOpen=false"
                    persistent
        >
            <div v-if="summoningInProgress">
                <h3 class="text-center mb-8 mt-4">- Summoning in progress -</h3>
                <v-progress-linear class="mb-8" indeterminate color="golden"/>
                <img src="/img/summoning/png/ritual-of-summoning.png" class="ritualImage"
                     style="margin-left:-15px"/>
            </div>
            <div v-if="morphingInProgress">
                <h3 class="text-center mb-4 mt-4">- Morphing in progress -</h3>
                <h4 class="text-center mb-6" v-if="morphingNewWizardId">Wizard #{{ morphingNewWizardId }}</h4>
                <v-progress-linear class="mb-8" indeterminate color="golden"/>
                <img class="ritualImage rounded" src="/img/summoning/png/morphing.png"
                     style="width:581px;height:324px;margin-left:-15px"
                />
            </div>
        </dialog-box>

        <dialog-box id="SummonConfirmation" :model="confirmationDialogOpen"
                    v-on:dialogClosed="confirmationDialogClosed();"
                    width="auto"
                    persistent
        >
            <div v-if="summonedElemental !== null"
                 class=" text--primary d-flex flex-column align-center justify-space-between mt-3">
                <h3 class="text-center golden--text mt-4 mb-4">Congratulations!</h3>
                <img :src="this.summonedElemental.thumbnail_300" class="elemental-avatar"
                     style="width:300px;height:300px;"/>
                <span class="caption text-center mb-4">
                    You've got a new
                    <b v-for="(elem, i) in summonedElemental.wizarre.elements" :key="`elem_${i}`" :class="elem"
                       class="text-capitalize"
                    >
                        {{ elem }}
                    </b>
                    Elemental #{{ summonedElemental.id }} !<br/>
                    It will grow into a Wizard in the <b>next 3 days</b>
                    </span>

                <span>You can check it on</span>
                <v-btn :to="{name: 'MyWizards', params: {}}" color="purple">
                    My Wizards
                </v-btn>

                <div v-if="summonedElemental.wizarre.land_power_user > 0"
                     class="mt-10 pt-6 text-center land-triggered-information">
                    <span class="golden--text text--black">{{ summonedElemental.wizarre.land_name }}</span> has
                    triggered during Ritual!
                    <br/>
                    <span
                        class="text-caption text--secondary font-italic">{{
                            summonedElemental.wizarre.land_power_description
                        }}</span>
                    <img src="/img/summoning/png/portal-icon@72x.png" class="portal-icon">
                </div>

                <div>

                </div>
            </div>
        </dialog-box>

        <dialog-box id="ErrorConfirmation" :model="errorDialogOpen"
                    v-on:dialogClosed="handleErrorDialogClose" width="auto"
                    title="Error occurred"
        >
            <img class="error-image rounded" src="/img/summoning/png/error.png"/>
        </dialog-box>
    </div>
</template>

<script>
import IconElement from "@/components/icon-element";
import SummonContract from "@/services/contracts/summonContract";
import DialogBox from "@/components/dialog-box";
import MiniEnhancement from "../assets/enhancements/mini-enhancement";
import MainButton from "../ui/main-button";
import SummonCostService from "../../services/summonCostService";
import Price from "../price";
import {apiServiceVue} from "../../services/apiService.vue";
import MiniEnhancementSlot from "../assets/enhancements/mini-enhancement-slot";
import {addDecimals} from "@/helpers/tokenHelper";
import * as timeHelper from "@/helpers/timeHelper";
import {UserWalletError} from "@/errors/WalletErrors";

export default {
    components: {MiniEnhancementSlot, Price, MainButton, MiniEnhancement, DialogBox, IconElement},
    props: {
        participants: Object,
        participantWizardIds: Array,
        participantEnchantmentWizardIds: Array,
        participantLandId: Number,
        participantEnchantmentLandId: String,
        participantIngredientIds: Array,
    },
    data() {
        return {
            summaryDialogOpen: false,
            progressDialogOpen: false,
            confirmationDialogOpen: false,
            errorDialogOpen: false,
            allowances: [
                {
                    name: 'SCRL token',
                    allowed: false,
                    checked: false,
                    loading: false,
                    approveRequest: this.approveTokenRequest,
                    checker: this.checkTokenAllowance,
                    shouldDisplay: () => this.isWalletConnected,
                },
                {
                    name: 'Enhancements',
                    allowed: false,
                    checked: false,
                    loading: false,
                    approveRequest: this.approveEnhancementRequest,
                    checker: this.checkEnhancementAllowance,
                    shouldDisplay: this.shouldDisplayEnhancementApproveBtn,
                }
            ],
            summoningInProgress: false,
            morphingInProgress: false,
            summonedElemental: null,
            summonContract: undefined,
            summaryKinshipPenalty: null,
            loadingSummaryPenalty: false,
            mounted: false,
            morphingNewWizardId: null,
            summonCostsFromApi: [],
        }
    },
    mounted: function () {
        this.mounted = true;
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.loadingStaking = false;
                this.init();
            });
        }
    },
    destroyed() {
        this.mounted = false;
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        allowanceValidations() {
            return [
                {
                    rule: !this.hasEnoughBalanceForSummon,
                    warning: "Not enough SCRL"
                },
                ...this.allowances
                    .filter(a => a.shouldDisplay())
                    .map(a => {
                        return ({
                            rule: !a.allowed,
                            warning: `${a.name} need to be allowed`
                        });
                    })
            ];
        },
        wizardValidations() {
            return [
                {
                    rule: this.wizardsSelectedAmount === 0,
                    warning: "Both wizards should be selected"
                },
                {
                    rule: this.wizardsSelectedAmount === 1,
                    warning: "Second wizard should be selected"
                },
                {
                    rule: !this.differentWizardOnBothSlots,
                    warning: "One wizard can't fill both summoning slots"
                },
                {
                    rule: !this.areWizardsMatured,
                    warning: "Wizard is not matured yet"
                },
            ]
        },
        landValidations() {
            return [
                {
                    rule: !this.landSelected,
                    warning: "Land should be selected"
                },
                {
                    rule: this.isLandOnCooldown,
                    warning: "Land is still recharging"
                },
            ]
        },
        ingredientsValidations() {
            return [
                {
                    rule: this.getIngredientsAmount(17) > 1,
                    warning: "Mushrumus Identitus don't stack"
                },
                {
                    rule: this.getIngredientsAmount(18) > 1,
                    warning: "Mushrumus Inheritus don't stack"
                },
                {
                    rule: this.getIngredientsAmount(18) > 0 && this.getIngredientsAmount(17) > 0,
                    warning: "Mushrumus Inheritus and Mushrumus Identitus are mutually exclusive"
                },
                {
                    rule: this.getIngredientsAmount(20) > 2,
                    warning: "Worm's Bones can't increase chance above 100%"
                },
                {
                    rule: this.getIngredientsAmount(21) > 0 && this.getIngredientsAmount(22) > 0,
                    warning: "Lipstick Frog's Spawn and No-Rainbow Toad's Spawn are mutually exclusive"
                },
                {
                    rule: (this.getIngredientsAmount(23) + this.getIngredientsAmount(24) + this.getIngredientsAmount(25)) > 1,
                    warning: "Nettle Leaf, Coleus Leaf, Dusty Miller Leaf are mutually exclusive and don't stack"
                },
                {
                    rule: this.getIngredientsAmount(27) > 2 && this.participants?.land?.data?.wizarre?.rarity === 'normal'
                        || this.getIngredientsAmount(27) > 1 && this.participants?.land?.data?.wizarre?.rarity === 'rare'
                        || this.getIngredientsAmount(27) > 0 && this.participants?.land?.data?.wizarre?.rarity === 'epic',
                    warning: "Land can't have rank higher than epic"
                },
                {
                    rule: (this.getIngredientsAmount(29) + this.getIngredientsAmount(30) + this.getIngredientsAmount(31)) > 1,
                    warning: "Unihorns are mutually exclusive and don't stack"
                },
                {
                    rule: (this.getIngredientsAmount(32) + this.getIngredientsAmount(33) + this.getIngredientsAmount(34)) > 1,
                    warning: "Toolboxes are mutually exclusive and don't stack"
                },
                {
                    rule: (this.getIngredientsAmount(32) > 0) && this.participants?.land?.data?.wizarre?.type === 3,
                    warning: "Can't use Soul Gate Toolbox on Soul Gate Land"
                },
                {
                    rule: (this.getIngredientsAmount(33) > 0) && this.participants?.land?.data?.wizarre?.type === 2,
                    warning: "Can't use Twin Beaks Toolbox on Twin Beaks Land"
                },
                {
                    rule: (this.getIngredientsAmount(34) > 0) && this.participants?.land?.data?.wizarre?.type === 1,
                    warning: "Can't use Voodoo Toolbox on Voodoo Land"
                },
                {
                    rule: (this.getIngredientsAmount(35) > 2),
                    warning: "You can't use more than two Uranium Stones"
                },
                {
                    rule: (this.getIngredientsAmount(35) > 0 && this.getIngredientsAmount(19) > 0),
                    warning: "Uranium Stone and Speedster Slime are mutually exclusive"
                },
                {
                    rule: (this.getIngredientsAmount(37) > 0 && this.participantWizardIds.length === 0),
                    warning: "There is need for at least one wizard for soul to be granted from Tamed Spirit"
                },
                {
                    rule: ((this.getIngredientsAmount(45) > 0) && (!this.participantLandId) && (this.getIngredientsAmount(33) === 0) && (this.getIngredientsAmount(34) === 0) && (this.getIngredientsAmount(35) === 0)),
                    warning: "Use Power Sugar with conjuration with any Toolbox or Land."
                },
            ]
        },
        summoningWarnings() {
            return [
                ...this.allowanceValidations,
                ...this.wizardValidations,
                ...this.landValidations,
                ...this.ingredientsValidations,
            ]
                .filter(w => w.rule)
                .map(w => w.warning);
        },
        summoningDisabled() {
            return this.summoningWarnings.length > 0;
        },
        isLandOnCooldown() {
            if (!!this.participants?.land && !!this.participants?.land?.type && 'land' === this.participants?.land?.type && !!this.participants?.land?.data?.wizarre?.cooldown_timestamp) {
                return timeHelper.dateToUtcMicroTimestamp(new Date()) <= timeHelper.timestampToMicroTimestamp(this.participants.land.data.wizarre.cooldown_timestamp);
            }

            return false;
        },
        areWizardsMatured() {
            for (const wiz of this.participants.wizards) {
                if (wiz.type && 'wizard' === wiz?.type && wiz?.data?.wizarre?.mature_on) {
                    return timeHelper.isDbDatePassed(wiz?.wizarre?.mature_on)
                }
            }

            return true;
        },
        hasEnoughBalanceForSummon() {
            return this.web3.balance >= addDecimals(this.summonCost);
        },
        landSelected() {
            return !!this.participantLandId || !!this.participantEnchantmentLandId;
        },
        wizardsSelectedAmount() {
            return [...this.participantWizardIds, ...this.participantEnchantmentWizardIds].length
        },
        participantsValid() {
            return this.landSelected
                && this.wizardsSelectedAmount === 2
                && this.differentWizardOnBothSlots;
        },
        differentWizardOnBothSlots() {
            return this.participantWizardIds?.length !== 2 || (this.participantWizardIds[0] !== this.participantWizardIds[1])
        },
        allAllowancesAllowed() {
            return this.allowances
                .map(allowance => allowance.allowed)
                .filter(Boolean).length >= this.allowances.filter(a => a.shouldDisplay()).length;
        },
        summonCost() {
            // Filter Wizards' souls
            const wizardsSoul = this.participants.wizards
                .filter(w => w.type === 'wizard')
                .filter(w => !!w.data?.id)
                .filter(w => w.data.id > 0)
                .map(w => w.data.wizarre.souls_remaining);

            const wizardsEnhancements = this.participants.wizards
                .filter(w => w.type === "ingredient-wizard")
                .filter(w => w.data?.id !== null)
                .length;

            // Don't calculate when nothing chosen
            if (
                0 === this.participantWizardIds.length
                && 0 === this.participantEnchantmentWizardIds.length
            ) {
                return null;
            }

            setTimeout(() => {
                this.checkAllowances();
            }, 10);

            if (this.summonCostsFromApi.length > 0) {
                return SummonCostService.summonCost(this.summonCostsFromApi, wizardsSoul, wizardsEnhancements);
            } else {
                return null;
            }
        },
        ingredientsWithoutSingleUseWizardLand() {
            return this.participants.ingredients.filter(item => 'ingredient' === item.data?.type);
        },
        countedIngredients() {
            return this.ingredientsWithoutSingleUseWizardLand
                .filter(i => !!i.data?.id)
                .map(i => i.data.id)
                .reduce((acc, curr) => {
                    acc[curr] = (acc[curr] || 0) + 1;
                    return acc;
                }, {})
        },
    },
    methods: {
        getIngredientsAmount(id) {
            return Number(this.countedIngredients[id] || 0);
        },
        confirmationDialogClosed() {
            this.confirmationDialogOpen = false;
            this.summonedElemental = null;
            window.location.reload();
        },
        init() {
            this.initializeSummonContract();
            this.checkAllowances();
            this.getSummonCostList();
        },
        initializeSummonContract() {
            this.summonContract = new SummonContract(this.web3);
        },
        checkAllowances() {
            this.allowances.forEach(allowance => {
                allowance.checker().then(result => {
                    allowance.allowed = result;
                    allowance.checked = true;
                });
            })
        },
        getSummonCostList() {
            apiServiceVue.get(process.env.VUE_APP_APP_URL + 'summon/costs')
                .then((res) => {
                    this.summonCostsFromApi = res.data;
                });
        },
        handleApproveRequestClick(allowance) {
            allowance.loading = true;
            allowance.approveRequest()
                .then((_) => {
                    this.checkAllowances();
                    setTimeout(() => {
                        this.$forceUpdate(); // update buttons status
                    }, 10)
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    allowance.loading = false;
                });
        },
        handleSummonClick() {
            this.summaryDialogOpen = false
            this.progressDialogOpen = true

            this.summoningInProgress = true;
            this.summonContract.summon(
                [this.participantLandId].filter(Boolean),   //can't pass "null" values, must be empty
                this.participantWizardIds,                  //can't pass "null" values, must be empty
                this.participantIngredientIds
            )
                .then(async (response) => {
                    this.summoningInProgress = false;
                    this.morphingInProgress = true;

                    const newWizardId = response.events.Summoned.returnValues.newWizardId;
                    this.morphingNewWizardId = newWizardId;
                    this.summonedElemental = await this.waitAndFetchWizardData(newWizardId);

                    if (!!this.summonedElemental.wizarre.land_power_user) {
                        const landPowerData = await this.fetchLandPowerData(this.summonedElemental.wizarre.land_power_user);
                        this.summonedElemental.wizarre.land_name = landPowerData.name;
                        this.summonedElemental.wizarre.land_power_description = landPowerData.description;
                    }

                    this.progressDialogOpen = false;
                    this.confirmationDialogOpen = true;
                    this.$store.dispatch('updateWeb3Balance').then();
                    this.$gtm.trackEvent({
                        'event': 'GA-Summon-Done',
                    });
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(
                            [this.participantLandId].filter(Boolean), this.participantWizardIds, this.participantIngredientIds,
                            error
                        );
                    }
                    this.errorDialogOpen = true;
                })
                .finally(() => {
                    this.progressDialogOpen = false
                    this.summoningInProgress = false;
                    this.morphingInProgress = false;
                });

            this.$gtm.trackEvent({
                'event': 'GA-Summon-Started',
            });
        },
        async waitAndFetchWizardData(id) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + id;
            while (this.mounted) {
                await (new Promise(resolve => {
                    setTimeout(resolve, 2000)
                })) // sleep

                const response = await apiServiceVue.getFullResponse(url);
                if (response.status === 200) {
                    await (new Promise(resolve => {
                        setTimeout(resolve, 5000)
                    }))
                    return (await response.json()).at(0);
                }
            }
        },
        async fetchLandPowerData(type) {
            const url = process.env.VUE_APP_LAND_NFT_SERVER_URL + 'power/' + type;
            const response = await apiServiceVue.getFullResponse(url);
            return response.json();
        },
        openSummary() {
            this.summaryDialogOpen = true;
            this.loadingSummaryPenalty = true;
            this.summaryKinshipPenalty = null;

            //load relation
            if (2 === this.participantWizardIds.length) {
                // Calculate penalty
                const url = process.env.VUE_APP_WIZARD_KINSHIP_URL + this.participantWizardIds[0] + ',' + this.participantWizardIds[1];
                apiServiceVue.request(url).then(kinship => {
                    if (kinship) {
                        this.summaryKinshipPenalty = kinship;
                    }
                    this.loadingSummaryPenalty = false;
                });
            } else {
                // Using Ephemeral -> no penalty
                this.summaryKinshipPenalty = null;
                this.loadingSummaryPenalty = false;
            }
        },
        async checkTokenAllowance() {
            return this.summonContract.tokenSpendCheckAllowance().then((result) => {
                if (this.summonCost) {
                    return result >= addDecimals(this.summonCost);
                }
                return result >= addDecimals(10000);
            })
        },
        async approveTokenRequest() {
            return this.summonContract.tokenSpendApprove();
        },
        shouldDisplayEnhancementApproveBtn() {
            return this.participantEnchantmentWizardIds.length > 0
                || !!this.participantEnchantmentLandId
                || this.participantIngredientIds.length > 0;
        },
        async checkEnhancementAllowance() {
            return this.summonContract.enhancementSpendCheckAllowance();
        },
        async approveEnhancementRequest() {
            return this.summonContract.enhancementSpendApprove();
        },
        handleErrorDialogClose() {
            this.errorDialogOpen = false;
        },
        haveSoul(participant) {
            return !!participant?.wizarre?.souls_max
        },
        getImage(participant) {
            return participant.thumbnail_150 || participant.thumbnail || participant.image
        },
    }
}
</script>

<style lang="scss" scoped>
.ingredient-header {
    width: 100%;
    text-align: center;
    border-top: 2px solid var(--v-frames-base);
    margin-top: 10px;
    padding-top: 20px;
    color: var(--v-frames-base);
    position: relative;
    font-weight: bold;

    &::before {
        width: 100%;
        height: 33px;
        display: block;
        content: '';
        background: url("/img/summoning/png/icon-plus@72x.png") 50% 0 no-repeat;
        position: absolute;
        margin-top: -38px;
    }
}

.dialog--participant-image {
    height: 100px;
}

.dialog--scrl-image {
    height: 40px;
}

.borderline {
    height: 0;
    width: 100%;
    border-bottom: 2px solid var(--v-frames-base);
}

.cost-arrow {
    max-height: 4vmin;
}

.elemental-image {
    width: 100%;
    padding-top: 100%;
    background-size: 140%;
    background-position: center;
    background-image: url("/img/summoning/png/ElementalNeutral.png");
}

.wizard-column {
    background-color: #38294a;
}

.mirrored {
    transform: scaleX(-1);
}

.ritualImage {
    max-height: 50vh;
    max-width: 80vw;
}

.summary-list {
    list-style-type: upper-roman;
}

.error-image {
    max-height: 50vh;
    max-width: 65vw;
}

.bottom-btn {
    width: 250px;
    display: block;
    text-align: center;
}

.nature {
    color: rgba(107, 240, 113);
}

.water {
    color: rgb(94, 229, 239);
}

.fire {
    color: rgb(239, 68, 29);
}

.land-triggered-information {
    width: 100%;
    border-top: 2px solid rgba(199, 172, 123, 0.63);
    position: relative;
}

.portal-icon {
    width: 42px;
    position: absolute;
    top: -23px;
    left: 50%;
    margin-left: -21px;
}

.elemental-avatar {
    margin: -15%
}

.summary-participant-name {
}

@media only screen and (max-width: 600px) {
    .summary-participant-name {
        min-height: 65px;
    }
}
</style>
