var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ritual fill-height" },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { "no-gutters": "" } },
        [
          _vm._l(_vm.wizards, function (wizard, i) {
            return _c(
              "v-col",
              {
                key: `wizard_${i}`,
                staticClass: "py-0",
                attrs: { order: i === 0 ? "first" : "last" },
                on: {
                  mouseenter: function ($event) {
                    wizard.hover = true
                  },
                  mouseleave: function ($event) {
                    wizard.hover = false
                  },
                  click: function ($event) {
                    return _vm.selectParticipant(
                      _vm.participants.wizards[i].type || "wizard",
                      _vm.participants.wizards[i].data,
                      i
                    )
                  },
                },
              },
              [
                _c("summoning-ritual-participant", {
                  attrs: {
                    selected: _vm.participants.wizards[i].selected,
                    hover: wizard.hover,
                    "placeholder-name": "wizard",
                    participant: _vm.participants.wizards[i].data,
                    mirror: i !== 0,
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "v-col",
            {
              key: "portal",
              staticClass: "py-0",
              attrs: { cols: "5" },
              on: {
                mouseenter: function ($event) {
                  _vm.land.hover = true
                },
                mouseleave: function ($event) {
                  _vm.land.hover = false
                },
                click: function ($event) {
                  return _vm.selectParticipant(
                    _vm.participants.land.type || "land",
                    _vm.participants.land.data
                  )
                },
              },
            },
            [
              _c("summoning-ritual-participant", {
                attrs: {
                  selected: _vm.participants.land.selected,
                  hover: _vm.land.hover,
                  "placeholder-name": "portal",
                  participant: _vm.participants.land.data,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }