var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isWalletConnected
    ? _c("div", { staticClass: "pt-15" }, [_c("connect-wallet")], 1)
    : _vm.waitingForWizards || _vm.waitingForLands || _vm.loadingEnhancements
    ? _c(
        "div",
        {
          staticClass:
            "full-size d-flex flex-column justify-center align-center",
        },
        [
          _c("h3", { staticClass: "text-center" }, [
            _vm._v("- Loading Assets -"),
          ]),
          _c("v-progress-circular", {
            staticClass: "full-size mt-8",
            attrs: { size: "100", indeterminate: "" },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-4 d-flex flex-column align-center justify-start" },
        [
          _c("div", [
            _vm.displayLands
              ? _c("div", [
                  0 === _vm.lands.length + _vm.enhancementLands.length
                    ? _c("div", [
                        _c("h3", { staticClass: "text-center mt-10" }, [
                          _vm._v("- No Lands -"),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap justify-center px-2 px-md-6",
                        },
                        [
                          _vm._l(
                            _vm.filteredAndSortedEnhancementLands,
                            function (enhancementLand) {
                              return _c(
                                "div",
                                {
                                  key: "le" + enhancementLand.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectEntity({
                                        type: "ingredient-land",
                                        data: enhancementLand,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("mini-enhancement", {
                                    staticClass: "ma-1",
                                    attrs: { item: enhancementLand },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(_vm.filteredAndSortedLands, function (land) {
                            return _c(
                              "div",
                              {
                                key: "l" + land.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.selectLandEntity(land)
                                  },
                                },
                              },
                              [
                                _c("mini-land", {
                                  staticClass: "ma-1",
                                  attrs: { item: land },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _vm.displayWizards
              ? _c("div", [
                  0 === _vm.wizards.length + _vm.enchantmentWizards.length
                    ? _c("div", [
                        _c("h3", { staticClass: "text-center mt-10" }, [
                          _vm._v("- No Wizards -"),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap justify-center px-2 px-md-6",
                        },
                        [
                          _vm._l(
                            _vm.filteredAndSortedEnchantmentWizards,
                            function (enhancementWizard) {
                              return _c(
                                "div",
                                {
                                  key: "we" + enhancementWizard.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectEntity({
                                        type: "ingredient-wizard",
                                        data: enhancementWizard,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("mini-enhancement", {
                                    staticClass: "ma-1",
                                    attrs: { item: enhancementWizard },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(
                            _vm.filteredAndSortedWizards,
                            function (wizard) {
                              return _c(
                                "div",
                                {
                                  key: "w" + wizard.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectWizardEntity(wizard)
                                    },
                                  },
                                },
                                [
                                  _c("mini-wizard", {
                                    directives: [
                                      { name: "title", rawName: "v-title" },
                                    ],
                                    staticClass: "ma-1",
                                    class: {
                                      "wizard-not-available":
                                        _vm.isWizardNotAvailable(wizard),
                                    },
                                    attrs: {
                                      title: _vm.quickWizardTooltip(wizard),
                                      item: wizard,
                                      hasPenalty:
                                        _vm.wizardKinshipPenaltyIds.includes(
                                          wizard.id
                                        ),
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _vm.displayIngredients
              ? _c("div", [
                  0 === _vm.filteredAndSortedIngredients.length
                    ? _c("div", [
                        _c("h3", { staticClass: "text-center mt-10" }, [
                          _vm._v("- No Supported Ingredients -"),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap justify-center px-2 px-md-6",
                        },
                        _vm._l(
                          _vm.filteredAndSortedIngredients,
                          function (ingredient) {
                            return _c(
                              "div",
                              {
                                key: "i" + ingredient.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.selectEntity({
                                      type: "ingredient",
                                      data: ingredient,
                                    })
                                  },
                                },
                              },
                              [
                                _c("mini-enhancement", {
                                  staticClass: "ma-1",
                                  attrs: { item: ingredient },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                ])
              : _vm._e(),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }