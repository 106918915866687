var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mini-item mini-enhancement-slot",
      attrs: { "data-id": _vm.item.id },
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            right: "",
            "max-width": "400",
            "content-class": "mini-tooltip",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b({ staticClass: "item-box" }, "div", attrs, false),
                      on
                    ),
                    [
                      _c("div", { staticClass: "item-content" }, [
                        _vm.item.img
                          ? _c("img", {
                              staticClass: "thumbnail-img",
                              attrs: { src: _vm.item.img },
                            })
                          : _vm._e(),
                        _vm.item.image
                          ? _c("img", {
                              staticClass: "thumbnail-img",
                              attrs: { src: _vm.item.image },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.item.name
            ? _c("span", { staticClass: "tooltip-content" }, [
                _c("span", { staticClass: "tooltip-title" }, [
                  _vm._v(_vm._s(_vm.item.name)),
                ]),
                _vm._v(" " + _vm._s(_vm.item.description) + " "),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }